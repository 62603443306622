import PolicyPLDFT from '../../docs/Cora_SCD_Politica_PLDFT.pdf'
import CustomerRelationshipPolicy from '../../docs/Cora_SCD_Politica_Relacionamento_Clientes_Usurios_v2.pdf'
import FinancialStatementJun20 from '../../docs/Cora_SCD_consolidado_jun_2020.pdf'
import FinancialStatementDez20 from '../../docs/Cora_SCD_consolidado_dez_2020.pdf'
import FinancialStatementJun21 from '../../docs/Cora_SCD_consolidado_jun_2021.pdf'
import FinancialStatementDez21 from '../../docs/Cora_SCD_consolidado_dez_2021.pdf'
import FinancialStatementJun22 from '../../docs/Cora_SCD_consolidado_jun_2022.pdf'
import FinancialStatementDez22 from '../../docs/Cora_SCD_consolidado_dez_2022.pdf'
import FinancialStatementJun23 from '../../docs/Cora_SCD_consolidado_jun_2023.pdf'
import FinancialStatementDez23 from '../../docs/Cora_SCD_consolidado_dez_2023.pdf'
import FinancialStatementJun24 from '../../docs/Cora_SCD_consolidado_jun_2024.pdf'
import FinancialStatementDez24 from '../../docs/Cora_SCD_consolidado_dez_2024.pdf'
import CodeOfEthicsAndConduct from '../../docs/codigo_de_etica_e_conduta.pdf'
import RiskAndLiquidityReport23 from '../../docs/Cora_relatorio_de_estrutura_de_gestão_do_risco_de_liquidez_23.pdf'
import RiskAndLiquidityReport24 from '../../docs/Cora_relatorio_de_estrutura_de_gestão_do_risco_de_liquidez_24.pdf'
import TransparencySalaryReportFirstSemester24 from '../../docs/relatorio_transparencia_salarial_SCD_1_2024.pdf'
import TransparencySalaryReport_SCFI_SecondSemester24 from '../../docs/relatorio_transparencia_salarial_SCFI_2_2024.pdf'
import TransparencySalaryReport_TECH_SecondSemester24 from '../../docs/relatorio_transparencia_salarial_TECH_2_2024.pdf'
import TransparencySalaryReport_SCFI_FirstSemester25 from '../../docs/relatorio_transparencia_salarial_SCFI_1_2025.pdf'
import TransparencySalaryReport_TECH_FirstSemester25 from '../../docs/relatorio_transparencia_salarial_TECH_1_2025.pdf'
import PolicyPRSAC from '../../docs/Cora_SCD_POL_016_Politica_de_Responsabilidade_Social_Ambiental_e_Climatica_PRSAC.pdf'
import PolicyCybersecurity from '../../docs/Cora_POL003_Politica_de_Seguranca_Cibernetica_v5_resumida.pdf'
import ReportPRSAC from '../../docs/Relatorio_de_Responsabilidade_Social_Ambiental_e_Climatica.pdf'
import ListenigChannel from '../../docs/Cora-REG002-Regulamento-de-Utilizacao-do-Canal-de-Escuta.pdf'
import CompliancePolicy from '../../docs/politica_de_compliance_versao_resumida.pdf'

export const leftItems: IRegulatorioList[] = [
  {
    menu: 'Termos de Uso',
    links: [
      {
        title: '  Termos e condições gerais da Cora',
        href: '/termos-uso',
      },
      {
        title: 'Termos de uso cartão de crédito',
        href: '/termos-de-uso-cartao-de-credito',
      },
      {
        title: 'Termos e condições de APIs',
        href: '/termos-e-condicoes-de-apis',
      },
      {
        title: 'Termos e Condições de Parceria Comercial',
        href: '/termos-e-condicoes-de-coraliados',
      },
      {
        title: 'Termos e Condições de Planos Pagos',
        href: '/termos-planos-pagos',
      },
      {
        title: 'Termos e Condições de uso do Cartão de Crédito com Garantia',
        href: '/termos-de-uso-do-cartao-de-credito-com-garantia',
      },
    ],
  },
  {
    menu: 'Políticas',
    links: [
      { title: 'Política de Privacidade', href: '/politica-privacidade' },
      {
        title: 'Política PLD/FT',
        href: PolicyPLDFT,
        target: '_blank',
      },
      {
        title: 'Política de Relacionamento com Clientes',
        href: CustomerRelationshipPolicy,
        target: '_blank',
      },
      {
        title: 'Política de Responsabilidade Social, Ambiental e Climática',
        href: PolicyPRSAC,
        target: '_blank',
      },
      {
        title: 'Política de Segurança Cibernética',
        href: PolicyCybersecurity,
        target: '_blank',
      },
      {
        title: 'Política de Compliance',
        href: CompliancePolicy,
        target: '_blank',
      },
    ],
  },
  {
    menu: 'Ética e Compliance',
    links: [
      {
        title: 'Código de Ética e Conduta',
        href: CodeOfEthicsAndConduct,
        target: '_blank',
      },
      {
        title: 'Canal de Escuta',
        href: 'https://my.safe.space/company/cora/y6ba6eeueol/',
        target: '_blank',
      },
      {
        title:
          'Relatório de Responsabilidade Social, Ambiental e Climática - 2022',
        href: ReportPRSAC,
        target: '_blank',
      },
      {
        title: 'Regulamento de Utilização do Canal de Escuta',
        href: ListenigChannel,
        target: '_blank',
      },
    ],
  },
  {
    menu: 'Relatório de Transparência Salarial',
    links: [
      {
        title: 'Relatório de Transparência Salarial - 1º Semestre/2024',
        href: TransparencySalaryReportFirstSemester24,
        target: '_blank',
      },
      {
        title:
          'Relatório de Transparência Salarial CORA SCFI - 2º Semestre/2024',
        href: TransparencySalaryReport_SCFI_SecondSemester24,
        target: '_blank',
      },
      {
        title:
          'Relatório de Transparência Salarial CORA TECH - 2º Semestre/2024',
        href: TransparencySalaryReport_TECH_SecondSemester24,
        target: '_blank',
      },
      {
        title:
          'Relatório de Transparência Salarial CORA SCFI - 1º Semestre/2025',
        href: TransparencySalaryReport_SCFI_FirstSemester25,
        target: '_blank',
      },
      {
        title:
          'Relatório de Transparência Salarial CORA TECH - 1º Semestre/2025',
        href: TransparencySalaryReport_TECH_FirstSemester25,
        target: '_blank',
      },
    ],
  },
]

export const rightItems: IRegulatorioList[] = [
  {
    menu: 'SAC - Serviço de Atendimento ao Consumidor',
    links: [
      {
        title: '0800 591 2350',
        href: 'tel:08005912350',
        target: '_blank',
      },
    ],
  },
  {
    menu: 'Central de Atendimento',
    links: [
      {
        title: 'meajuda@cora.com.br',
        href: 'mailto:meajuda@cora.com.br',
        target: '_blank',
      },
      {
        title: '(11) 9 3305 8198 (WhatsApp)',
        href: 'https://api.whatsapp.com/send/?phone=5511933058198',
        target: '_blank',
      },
    ],
  },
]

export const financialLeftItems: IRegulatorioList[] = [
  {
    menu: 'Demonstrações financeiras',
    links: [
      {
        title: 'Demonstrações Financeiras CORA SCFI - 1º Semestre/2020',
        href: FinancialStatementJun20,
        target: '_blank',
      },
      {
        title: 'Demonstrações Financeiras CORA SCFI - 2º Semestre/2020',
        href: FinancialStatementDez20,
        target: '_blank',
      },
      {
        title: 'Demonstrações Financeiras CORA SCFI - 1º Semestre/2021',
        href: FinancialStatementJun21,
        target: '_blank',
      },
      {
        title: 'Demonstrações Financeiras CORA SCFI - 2º Semestre/2021',
        href: FinancialStatementDez21,
        target: '_blank',
      },
      {
        title: 'Demonstrações Financeiras CORA SCFI - 1º Semestre/2022',
        href: FinancialStatementJun22,
        target: '_blank',
      },
      {
        title: 'Demonstrações Financeiras CORA SCFI - 2º Semestre/2022',
        href: FinancialStatementDez22,
        target: '_blank',
      },
      {
        title: 'Demonstrações Financeiras CORA SCFI - 1º Semestre/2023',
        href: FinancialStatementJun23,
        target: '_blank',
      },
      {
        title: 'Demonstrações Financeiras CORA SCFI - 2º Semestre/2023',
        href: FinancialStatementDez23,
        target: '_blank',
      },
      {
        title: 'Demonstrações Financeiras CORA SCFI - 1º Semestre/2024',
        href: FinancialStatementJun24,
        target: '_blank',
      },
      {
        title: 'Demonstrações Financeiras CORA SCFI - 2º Semestre/2024',
        href: FinancialStatementDez24,
        target: '_blank',
      },
    ],
  },
]

export const financialRightItems: IRegulatorioList[] = [
  {
    menu: 'Relatórios',
    links: [
      {
        title: 'Relatório de Estrutura de Gestão de Risco de Liquidez - 2023',
        href: RiskAndLiquidityReport23,
        target: '_blank',
      },
      {
        title: 'Relatório de Estrutura de Gestão de Risco de Liquidez - 2024',
        href: RiskAndLiquidityReport24,
        target: '_blank',
      },
    ],
  },
]
